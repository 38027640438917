import React from "react";


class App extends React.Component {
  render() {
    return (
      <div id="root" className="wrapper">
      <table border="0">
          <caption><b><h1>Телефоны сотрудников</h1></b></caption>
          <tr>
              <th bgcolor="25b334">РК ННДК <br/>233-233-1</th>
              <th bgcolor="3380FF">ННДК <br/>435-18-75</th>
              <th bgcolor="ff8c00">Выбор-ННДК <br/>435-18-57</th>
              </tr>
          <tr>
              <td bgcolor="7EDF7D">100 — Старкова Олеся</td>
              <td bgcolor="6EC8FF">101 — Приемная</td>
              <td bgcolor="FFCE5B">101 — Зеленова Наталья</td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">101 — Назаров Александр</td>
              <td bgcolor="6EC8FF">102 — Куликов Евгений</td>
              <td bgcolor="FFCE5B">102 — Иванов Сергей Алексеевич</td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">102 — Иванов Михаил Сергеевич</td>
              <td bgcolor="6EC8FF">104 — Кислова Елена</td>
              <td bgcolor="FFCE5B">103 — Назаров Александр</td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">103 — Левина Светлана</td>
              <td bgcolor="6EC8FF">105 — Артамонов Леонид</td>
              <td bgcolor="FFCE5B">104 — Королев Андрей</td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">104 — Кузнецова Елена</td>
              <td bgcolor="6EC8FF">106 — Коротков Кирилл</td>
              <td bgcolor="FFCE5B">105 — Артамонов Леонид</td>
          </tr>
              <td bgcolor="7EDF7D">105 — Назарова Ольга</td>
              <td bgcolor="6EC8FF">107 — Иванов Виктор</td>
              <td bgcolor="FFCE5B">106 — Назарова Ольга</td>
          <tr>
              <td bgcolor="7EDF7D">107 — Ижикова Вера</td>
              <td bgcolor="6EC8FF">110 — Администратор</td>
              <td bgcolor="FFCE5B">107 — Иванов Михаил Сергеевич</td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">108 — Галишникова Екатерина</td>
              <td bgcolor="6EC8FF">111 — Мурадымов Михаил</td>
              <td bgcolor="FFCE5B">109 — Моисеев Дмитрий</td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">109 — Нестерова Ольга</td>
              <td bgcolor="6EC8FF">116 — Кабанов Никита</td>
              <td bgcolor="FFCE5B">111 — Мурадымов Михаил</td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">110 — Колпакова Мария</td>
              <td bgcolor="6EC8FF">201 — Иванов Михаил Сергеевич</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">111 — Мурадымов Михаил</td>
              <td bgcolor="6EC8FF">202 — Назарова Ольга</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">112 — Иванов Сергей Алексеевич</td>
              <td bgcolor="6EC8FF">203 — Голубева Мария</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D">113 — Садова Дина</td>
              <td bgcolor="6EC8FF">204 — Иванов Сергей Алексеевич</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">205 — Матвеенко Игорь Владимирович</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">501 — Воронов Евгений</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">601 — Иванова Елена</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">602 — Задаура Евгений</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">701 — Фадеев Александр Александрович</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="3380FF"><b><center><font color="#f8f8ff">435-17-95</font></center></b></td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">301 — Капринина Ольга</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">302 — Прокопьева Ольга</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">304 — Попович Ольга</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">305 — Моисеева Алла</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">306 — Герасимова Ольга</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">307 — Логинова Татьяна</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">308 — Аверина Юлия</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">309 — Смирнов Сергей</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">310 — Хорев Владимир</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">311 — Грызлова Татьяна</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">312 — Мартьянова Мария</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">313 — Чижова Татьяна</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">314 — Егунова Мария</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">315 — Зорькин Андрей</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">316 — Репина Юлия</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">317 — Голубо Оксана</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">318 — Зайцев Константин</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">319 — Колин Сергей</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">320 — Мещеров Шамиль</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">321 — Прохорова Марина</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">322 — Козырева Александра</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">323 — Монастырская Наталья</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">324 — Печенин Алексей</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">325 — Горячев Сергей</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">326 — Кузьминова Марина</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">327 — Терещенко Полина</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">328 — Елантьева Елена</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">329 — Котрухов Кирилл</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">330 — Бахарев Александр</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">331 — Кириллова Ангелина</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">332 — Полонецкий Михаил</td>
              <td bgcolor="FFCE5B"></td>
          </tr>         
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">334 — Фомичев Максим</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="3380FF"><b><center><font color="#f8f8ff">435-17-41</font></center></b></td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">401 — Фунтикова Алла</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">402 — Довбыш Елена</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">403 — Зеленова Наталья</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">404 — Герасимова Юлия</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">405 — Мордаева Елена</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">406 — Калынова Юлия</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">407 — Абрамова Карина</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">408 — Симутина Светлана</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">409 — Куликова Людмила</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">410 — Шилова Наталья</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">411 — Касьянычева Елена</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">412 — Котрухова Марина</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
          <tr>
              <td bgcolor="7EDF7D"></td>
              <td bgcolor="6EC8FF">414 — Ливен Мария</td>
              <td bgcolor="FFCE5B"></td>
          </tr>
      </table>
      </div>
    )
  }
}

export default App;
